import React from "react"
import { graphql, Link } from "gatsby"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import DocumentNotes from "../components/document-notes"
import DownloadImages from "../components/download-images"
import DownloadMedia from "../components/download-media"
import Layout from "../components/layout"
import LinkedNodes from "../components/linked-nodes"
import MediaTags from "../components/media-tags"
import MoreInfo from "../components/more-info"
import PdfIcon from "../components/pdf-icon"
import SEO from "../components/seo"
import Breadcrumbs from "../components/breadcrumb"

const MediaPageTemplate = ({ data, pageContext }) => {
  const title = data.page.frontmatter.title
  const name = data.page.frontmatter.name
  const tags = data.page.frontmatter.tags
  const type = data.page.frontmatter.type
  const path = data.page.frontmatter.path
  const ext = data.page.frontmatter.ext
  const html = data.page.html
  const pagesLinkedBy = data.linked.nodes
  let downloadDiv
  let media
  const crumbs = [
    {
      title: "Archives",
      slug: "/archives"
    }
  ]

  if (type === "image") {
    downloadDiv = <DownloadImages path={path} name={name} ext={ext} />
    media = (
      <img
        id="image-media"
        src={`${path}/${name}-media_view.${ext}`}
        alt={title}
      />
    )
    crumbs.push({ title: "Images", slug: "/archives/images" })
  }
  if (type === "document") {
    downloadDiv = <DownloadMedia path={path} name={name} ext={ext} />
    media = (
      <a
        id="document-media"
        href={`${path}/${name}.${ext}`}
        aria-label={`View ${title} document`}
      >
        <PdfIcon />
      </a>
    )
    crumbs.push({ title: "Media", slug: "/mediasearch" })
  }
  if (type === "video") {
    downloadDiv = <DownloadMedia path={path} name={name} ext={ext} />
    media = (
      <div>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video controls id="video-media" poster={`${path}/${name}.jpg`}>
          <source src={`${path}/${name}.${ext}`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    )
    crumbs.push({ title: "Videos", slug: "/archives/videos" })
  }
  return (
    <Layout>
      <SEO title={title} />
      <Row>
        <Col id="page-title" className="col col-12">
          <h1 className="title">{title}</h1>
        </Col>
      </Row>
      <Breadcrumbs crumbs={crumbs} />
      <Row id="content">
        <Col>
          <Row>
            <Col s="12" md="8" id="main-content">
              <Row>{media}</Row>
              <Row>{html ? <MoreInfo summary={html} /> : ""}</Row>
            </Col>
            <Col>
              <div
                className="media_container_details sidebar"
                id="sidebar-second"
              >
                {downloadDiv}
                <MediaTags tags={tags} />
                <LinkedNodes links={pagesLinkedBy} />
                <DocumentNotes />
              </div>
            </Col>
          </Row>
          <Row className="text-center">
            <Col>
              <Link
                to={`/mediasearch/${pageContext.pg}`}
                className="highlight-link-yellow"
              >
                Back to Media Search
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  )
}

export const query = graphql`
  query($mid: Int!) {
    page: markdownRemark(frontmatter: { mid: { eq: $mid } }) {
      frontmatter {
        mid
        title
        name
        path
        tags
        type
        ext
      }
      html
    }
    linked: allMarkdownRemark(
      filter: { fields: { linked_media: { in: [$mid] } } }
    ) {
      nodes {
        frontmatter {
          title
          slug
        }
      }
    }
  }
`

export default MediaPageTemplate
