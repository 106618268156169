import React from "react"
import DocumentDownload from "./document-download"
import VideoDownload from "./video-download"

const DownloadMedia = ({ path, name, ext }) => {
  let output
  if (ext === "pdf") {
    output = <DocumentDownload path={path} name={`${name}`} ext={ext} />
  } else {
    output = <VideoDownload path={path} name={`${name}`} ext={ext} />
  }
  return (
    <div className="block">
      <h4 className="block-title">Download Media File</h4>
      <div className="block-contents">{output}</div>
    </div>
  )
}

export default DownloadMedia
