import React from "react"

const ImageDownload = ({ path, name, ext, size }) => (
  <>
    <a href={`${path}/${name}.${ext}`}>Download {size} image &gt;</a>
    <br />
  </>
)

export default ImageDownload
