import React from "react"
import ImageDownload from "./image-download"

const imageDerivatives = {
  Original: "",
  "Medium-sized": "-media_view",
  Small: "-relatedinfo"
}

const DownloadImages = ({ path, name, ext }) => (
  <div className="block">
    <h4 className="block-title">Download Image</h4>
    <div className="block-contents">
      {Object.keys(imageDerivatives).map((keyName, keyIndex) => (
        <ImageDownload
          key={keyIndex}
          path={path}
          name={`${name}${imageDerivatives[keyName]}`}
          ext={ext}
          size={keyName}
        />
      ))}
    </div>
  </div>
)

export default DownloadImages
