import React from "react"

const DocumentDownload = ({ path, name, ext }) => (
  <div>
    <a href={`${path}/${name}.${ext}`}>Download Document &gt;</a>
    <br />
  </div>
)

export default DocumentDownload
