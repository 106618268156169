import React from "react"

const VideoDownload = ({ path, name, ext }) => (
  <div>
    <a href={`${path}/${name}.${ext}`}>Download Video &gt;</a>
    <br />
  </div>
)

export default VideoDownload
