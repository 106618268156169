import React from "react"
import { Link } from "gatsby"

const LinkedNodes = ({ links }) => {
  if (links && links.length) {
    return (
      <div className="block">
        <h4 className="block-title">Linked To By</h4>
        <div className="block-contents">
          {links.map(({ frontmatter: page }, key) => (
            <span key={key}>
              <Link to={page.slug}>{page.title} &gt;</Link>
            </span>
          ))}
        </div>
      </div>
    )
  }
  return null
}

export default LinkedNodes
