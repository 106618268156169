import React from "react"

const MoreInfo = ({ summary }) => (
  <div className="more-info">
    <h4>More Info</h4>
    <div
      className="media-summary"
      dangerouslySetInnerHTML={{ __html: summary }}
    />
  </div>
)

export default MoreInfo
