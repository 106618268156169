import React from "react"

const DocumentNotes = () => (
  <div className="block" id="disclaimer">
    <h4 className="block-title">Disclaimer</h4>
    <div className="block-contents">
      Photographs and other media on this site are the property of the Georgia
      Institute of Technology. Permission is hereby granted to use these
      photographs and other media for news/editorial purposes in newspapers,
      magazines, newsletters, Web sites and broadcast news reports. Please
      credit the "Georgia Tech Research Institute." For other uses, please
      contact{" "}
      <a href="mailto:comminfo@gtri.gatech.edu">comminfo@gtri.gatech.edu</a> and
      include a link to the specific image or page containing the image for
      which you are requesting permission.
    </div>
  </div>
)

export default DocumentNotes
