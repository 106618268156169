import React from "react"
import { Link } from "gatsby"

const _ = require("lodash")

const MediaTags = ({ tags }) => {
  let output
  if (tags && tags.length) {
    output = tags.map((tag, key) => (
      <Link key={key} to={`/tags/${_.kebabCase(tag)}`}>
        {" "}
        {tag} &gt;{" "}
      </Link>
    ))
  } else {
    output = <p>No tags found</p>
  }
  return (
    <div className="block">
      <h4 className="block-title">{tags.length > 1 ? `Tags` : `Tag`}</h4>
      <div className="block-contents">{output}</div>
    </div>
  )
}

export default MediaTags
